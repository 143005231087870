import * as styles from './CareersContactPeopleBlock.module.scss';
import React from 'react';
import {BlockTitle} from "../BlockTitle";
import {Typography} from "../Typography";
import {CareersContactPeople} from "../CareersContactPeople";
import {componentRef} from "../../functions/componentRef";

export function CareersContactPeopleBlock({title, content, people}) {
    return <section className={styles.root} {...componentRef('career-contact')}>
        <div className={styles.contentWrapper}>
            <BlockTitle className={styles.title}>{title}</BlockTitle>
            <Typography className={styles.content}>{content}</Typography>
        </div>
        <CareersContactPeople firstPerson={people[0]} secondPerson={people[1]} />
    </section>
}
