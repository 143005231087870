import * as styles from './CareersJobs.module.scss';
import React from 'react';
import classNames from 'classnames';
import {BlockTitle} from "../BlockTitle";
import {Typography} from "../Typography";
import {PageMainColumnWrapper} from "../PageMainColumnWrapper";
import {MarkdownContent} from "../MarkdownContent";
import {componentRef} from "../../functions/componentRef";
import {sendAnalyticsEvent} from "../../functions/sendAnalyticsEvent";

export function CareersJobs({title, content, jobs, className, ...otherProps}) {
    return <section className={classNames(className, styles.root)}
                    {...otherProps}
                    {...componentRef('career-jobs')}>
        <PageMainColumnWrapper>
            <div className={styles.contentWrapper}>
                <BlockTitle className={styles.title}>{title}</BlockTitle>
                {content ? <MarkdownContent component={Typography} className={styles.content} content={content} /> : undefined}
            </div>
            <div className={styles.jobs}>
                {jobs.map((x, index) => {
                    return <Job name={x.name}
                                key={index}
                                url={x.url}
                                content={x.description}/>;
                })}
            </div>
        </PageMainColumnWrapper>
    </section>
}

function Job({name, content, url}) {
    return <a href={url}
              target="_blank"
              onMouseDown={() => {
                  sendAnalyticsEvent('link.job.click')
              }}
              rel="nofollow noopener noreferrer"
              {...componentRef.subcomponent('job')}
              className={styles.job}>
        <article className={styles.jobWrapper}>
            <h1 className={styles.jobName}>{name}</h1>
            <MarkdownContent component={Typography} content={content} className={styles.jobContent} />
        </article>
    </a>

}
