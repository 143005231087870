// extracted by mini-css-extract-plugin
export var contentWrapper = "CareersOpportunities-module--contentWrapper--5W+Qe";
export var image = "CareersOpportunities-module--image--y-Wmx";
export var imageWrapper = "CareersOpportunities-module--imageWrapper--mjlpo";
export var opportunities = "CareersOpportunities-module--opportunities--GHrg6";
export var opportunitiesWrapper = "CareersOpportunities-module--opportunitiesWrapper---8uiG";
export var opportunity = "CareersOpportunities-module--opportunity--YKnE9";
export var opportunityActive = "CareersOpportunities-module--opportunityActive--341BD";
export var opportunityContent = "CareersOpportunities-module--opportunityContent--mMIly";
export var opportunityTitle = "CareersOpportunities-module--opportunityTitle--97KZk";
export var title = "CareersOpportunities-module--title--IMy0t";