import React from 'react';
import * as styles from './RecruitmentProcess.module.scss';
import classNames from 'classnames';
import {BlockTitle} from "../BlockTitle";
import {MarkdownContent} from "../MarkdownContent";
import {Typography} from "../Typography";
import {componentRef} from "../../functions/componentRef";

export function RecruitmentProcess({title, content, steps, className, ...otherProps}) {
    return <section className={classNames(className, styles.root)} {...otherProps} {...componentRef('recruit-process')}>
        <BlockTitle className={styles.title}>{title}</BlockTitle>
        <Typography className={styles.content}>{content}</Typography>
        <div className={styles.steps}>
            {steps.map((x, index) => {
                return <Step key={index} number={index + 1} title={x.title} content={x.content}/>;
            })}
        </div>
    </section>
}

function formatNumber(number) {
    const str = number + '';
    return str.length === 1 ? '0' + str : str;
}

function Step({title, content, number}) {
    return <div className={styles.step} {...componentRef.subcomponent('recruit-step')}>
        <span className={styles.stepNumber} {...componentRef.subcomponent('step-number')}>{formatNumber(number)}</span>
        <h2 className={styles.stepTitle} {...componentRef.subcomponent('step-title')}>{title}</h2>
        <MarkdownContent className={styles.stepContent} component={Typography} content={content}/>
    </div>
}