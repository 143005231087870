export function sendAnalyticsEvent(action, category, label, value) {
    if (process.env.NODE_ENV === 'development') {
        console.log('Sending event', {action, category, label, value});
    }
    global.gtag && global.gtag('event', action, {
        event_category: category,
        event_label: label,
        value
    });
}
